<template>
  <div class="wrap">
    <div class="wrap-header">
      <el-breadcrumb>
        <el-breadcrumb-item class="font-breadcrumb" :to="{ path: '/cargo-tracking' }"><a>Cargo Tracking </a></el-breadcrumb-item>
        <el-breadcrumb-item>Tracking Result</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="wrap-content">
      <el-table
        :data="trackingData"
        row-key="id"
        :expand-row-keys="expands"
        @row-dblclick="openExpands"
        @expand-change="expandChange"
        class="content-table">
        <el-table-column
          type="expand"
          width="50">
          <template slot-scope="props">
            <el-table
              :data="props.row.expand"
              :cell-style="cellStyleExpand"
              :show-header="false"
              class="expandTable">
              <el-table-column
                width="250"
                class-name="border">
                <template slot-scope="prop">
                  <div :id="'title'+prop.$index" :ref="'title'+prop.$index">
                    <svg-icon v-if="prop.row.transportationTitle == 'Truck Arrival at Terminal'" icon-class="truck_icon" style="width:35px;height:24px;border-radius: 12px;margin-left:20px;margin-right:10px;vertical-align: -5px;"/>
                    <svg-icon v-if="prop.row.transportationTitle === 'Departed' || prop.row.transportationTitle === 'Booked with Carrier'" icon-class="airplain_icon" style="width:35px;height:24px;border-radius: 12px;margin-left:20px;margin-right:10px;vertical-align: -5px;"/>
                    <span>{{prop.row.transportationTitle}}</span>
                  </div>
                </template>
              </el-table-column>
              <el-table-column>
                <template slot-scope="prop">
                  <span>{{prop.row.port}}</span>
                </template>
              </el-table-column>
              <el-table-column>
                <template slot-scope="prop">
                  <span>{{prop.row.time}}</span>
                </template>
              </el-table-column>
               <el-table-column>
                <template slot-scope="prop">
                  <span>{{prop.row.trainNumber}}</span>
                </template>
              </el-table-column>
              <el-table-column width="230">
                <template slot-scope="prop">
                  <div class="truck-number-center">
                    <span>{{prop.row.truckNumber}}</span>
                  </div>
                </template>
              </el-table-column>
            </el-table>
          </template>
        </el-table-column>
        <el-table-column
          prop="awb"
          label="AWB#"
          align="center"
          label-class-name="title">
        </el-table-column>
        <el-table-column
          prop="dest"
          label="Dest."
          align="center"
          label-class-name="title">
        </el-table-column>
        <el-table-column
          prop="pieces"
          label="Total Pieces"
          align="center"
          label-class-name="title">
        </el-table-column>
        <el-table-column
          prop="weight"
          label="Weight"
          align="center"
          label-class-name="title">
        </el-table-column>
      </el-table>
    </div>
    <div class="pagination">
      <el-pagination
        @size-change="sizeChange"
        @current-change="currentChange"
        :current-page="currentPage"
        :page-sizes="[10, 20, 30, 40]"
        :page-size="pageSize"
        layout="total, prev, slot, next, sizes, jumper, ->"
        :total="total">
        <span class="on-pager">{{ currentPage }}</span>
      </el-pagination>
    </div>
  </div>
</template>

<script>
import { getOfficeCode } from '@/utils/auth'
import { trackingSubmit } from '@/api/cargo-tracking/cargo-tracking-api.js'

export default {
  name: 'tracking-result',
  data () {
    return {
      params: {},
      currentPage: 1,
      pageSize: 10,
      total: 0,
      exist: [],
      trackingData: [],
      expands: [],
      existenceTransportationArray: []
    }
  },
  mounted () {
    this.init()
  },
  methods: {
    init () {
      this.$set(this.params, 'officeCode', getOfficeCode())
      if (this.$route.query) {
        for (let i in this.$route.query) {
          this.$set(this.params, i, this.$route.query[i])
        }
      }
      let obj = {
        data: this.params,
        loading: true
      }
      trackingSubmit(obj).then(res => {
        if (res.data.CODE === 200) {
          let resulst = res.data.DATA
          this.trackingData = []
          this.total = resulst.totalNum
          this.currentPage = resulst.currentPage
          this.pageSize = resulst.pageSize
          resulst.datas.forEach((element, index) => {
            let expandData = []
            element.milestone.forEach((item, id) => {
              expandData.push({
                id: `${id + 1}`,
                transportationTitle: item.status,
                port: item.port,
                time: item.date,
                trainNumber: item.reference1,
                truckNumber: item.reference2
              })
            })
            this.trackingData.push({
              id: `tracking_${index + 1}`,
              awb: element.awb_no,
              dest: element.dest_airport,
              pieces: element.totalPieces,
              weight: element.weight,
              expand: expandData
            })
          })
          this.trackingData.forEach(item => {
            let newExpandData = []
            let newExpandExit = []
            let count = 0
            item.expand.forEach((element, index) => {
              if (newExpandExit.indexOf(element.transportationTitle) === -1) {
                newExpandExit.push(element.transportationTitle)
              }
              if (newExpandExit.indexOf(element.transportationTitle) !== -1 && (index === 0 ? element.transportationTitle === newExpandExit[index] : element.transportationTitle === newExpandExit[index - count])) {
                newExpandData.push({
                  id: element.id,
                  transportationTitle: element.transportationTitle,
                  port: element.port,
                  time: element.time,
                  trainNumber: element.trainNumber,
                  truckNumber: element.truckNumber,
                  traffic: element.transportationTitle
                })
              } else {
                count++
                newExpandData.push({
                  id: element.id,
                  transportationTitle: '',
                  repeat: true,
                  port: element.port,
                  time: element.time,
                  trainNumber: element.trainNumber,
                  truckNumber: element.truckNumber,
                  traffic: element.transportationTitle
                })
              }
            })
            item.expand = newExpandData
          })
          // this.$message({
          //   dangerouslyUseHTMLString: true,
          //   message: 'Query Successfully!',
          //   type: 'success',
          //   showClose: true,
          //   duration: 8000,
          //   offset: 120
          // })
          if (this.trackingData.length > 0) {
            this.expands = [this.trackingData[0].id]
          }
        }
      }).catch(err => {
        console.log(err)
      })
    },
    expandChange (row) {
      this.expands = this.expands.indexOf(row.id) !== -1 ? [] : [row.id]
    },
    cellStyleExpand ({ row }) {
      if (row.repeat && row.transportationTitle === '') return 'border-top: none;'
    },
    sizeChange (val) {
      this.pageSize = val
      this.$set(this.params, 'pageSize', val)
      this.$set(this.params, 'currentPage', this.currentPage)
      let obj = {
        data: this.params,
        loading: true
      }
      trackingSubmit(obj).then(res => {
        if (res.data.CODE === 200) {
          let resulst = res.data.DATA
          this.trackingData = []
          this.total = resulst.totalNum
          this.currentPage = resulst.currentPage
          this.pageSize = resulst.pageSize
          resulst.datas.forEach((element, index) => {
            let expandData = []
            element.milestone.forEach((item, id) => {
              expandData.push({
                id: `${id + 1}`,
                transportationTitle: item.status,
                port: item.port,
                time: item.date,
                trainNumber: item.reference1,
                truckNumber: item.reference2
              })
            })
            this.trackingData.push({
              id: `tracking_${index + 1}`,
              awb: element.awb_no,
              dest: element.dest_airport,
              pieces: element.totalPieces,
              weight: element.weight,
              expand: expandData
            })
          })
          this.trackingData.forEach(item => {
            let newExpandData = []
            let newExpandExit = []
            let count = 0
            item.expand.forEach((element, index) => {
              if (newExpandExit.indexOf(element.transportationTitle) === -1) {
                newExpandExit.push(element.transportationTitle)
              }
              if (newExpandExit.indexOf(element.transportationTitle) !== -1 && (index === 0 ? element.transportationTitle === newExpandExit[index] : element.transportationTitle === newExpandExit[index - count])) {
                newExpandData.push({
                  id: element.id,
                  transportationTitle: element.transportationTitle,
                  port: element.port,
                  time: element.time,
                  trainNumber: element.trainNumber,
                  truckNumber: element.truckNumber,
                  traffic: element.transportationTitle
                })
              } else {
                count++
                newExpandData.push({
                  id: element.id,
                  transportationTitle: '',
                  repeat: true,
                  port: element.port,
                  time: element.time,
                  trainNumber: element.trainNumber,
                  truckNumber: element.truckNumber,
                  traffic: element.transportationTitle
                })
              }
            })
            item.expand = newExpandData
          })
          // this.$message({
          //   dangerouslyUseHTMLString: true,
          //   message: 'Query Successfully!',
          //   type: 'success',
          //   showClose: true,
          //   duration: 8000,
          //   offset: 120
          // })
          if (this.trackingData.length > 0) {
            this.expands = [this.trackingData[0].id]
          }
        }
      }).catch(err => {
        console.log(err)
      })
    },
    currentChange (val) {
      this.$set(this.params, 'pageSize', this.pageSize)
      this.$set(this.params, 'currentPage', val)
      let obj = {
        data: this.params,
        loading: true
      }
      trackingSubmit(obj).then(res => {
        if (res.data.CODE === 200) {
          let resulst = res.data.DATA
          this.trackingData = []
          this.total = resulst.totalNum
          this.currentPage = resulst.currentPage
          this.pageSize = resulst.pageSize
          resulst.datas.forEach((element, index) => {
            let expandData = []
            element.milestone.forEach((item, id) => {
              expandData.push({
                id: `${id + 1}`,
                transportationTitle: item.status,
                port: item.port,
                time: item.date,
                trainNumber: item.reference1,
                truckNumber: item.reference2
              })
            })
            this.trackingData.push({
              id: `tracking_${index + 1}`,
              awb: element.awb_no,
              dest: element.dest_airport,
              pieces: element.totalPieces,
              weight: element.weight,
              expand: expandData
            })
          })
          this.trackingData.forEach(item => {
            let newExpandData = []
            let newExpandExit = []
            let count = 0
            item.expand.forEach((element, index) => {
              if (newExpandExit.indexOf(element.transportationTitle) === -1) {
                newExpandExit.push(element.transportationTitle)
              }
              if (newExpandExit.indexOf(element.transportationTitle) !== -1 && (index === 0 ? element.transportationTitle === newExpandExit[index] : element.transportationTitle === newExpandExit[index - count])) {
                newExpandData.push({
                  id: element.id,
                  transportationTitle: element.transportationTitle,
                  port: element.port,
                  time: element.time,
                  trainNumber: element.trainNumber,
                  truckNumber: element.truckNumber,
                  traffic: element.transportationTitle
                })
              } else {
                count++
                newExpandData.push({
                  id: element.id,
                  transportationTitle: '',
                  repeat: true,
                  port: element.port,
                  time: element.time,
                  trainNumber: element.trainNumber,
                  truckNumber: element.truckNumber,
                  traffic: element.transportationTitle
                })
              }
            })
            item.expand = newExpandData
          })
          // this.$message({
          //   dangerouslyUseHTMLString: true,
          //   message: 'Query Successfully!',
          //   type: 'success',
          //   showClose: true,
          //   duration: 8000,
          //   offset: 120
          // })
          if (this.trackingData.length > 0) {
            this.expands = [this.trackingData[0].id]
          }
        }
      }).catch(err => {
        console.log(err)
      })
    },
    openExpands (row) {
      this.expands = [row.id]
    }
  }
}
</script>

<style lang="scss" scoped>
$bg1:#1890ff;
.wrap {
  .wrap-header {
    display: flex;
    padding: 15px;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #e5e5e5;
  }
  .wrap-content{
    padding: 5px 24px 24px;
    min-height: 72vh;
    .content-table{
      width: 100%;
      border-bottom: 1px solid #EBEEF5;
    }
    .expandTable {
      width: 92%;
      margin: 0 auto;
      border-left: 1px solid #e5e5e5;
      border-right: 1px solid #e5e5e5;
      border-bottom: 1px solid #e5e5e5;
      .border {
        border-bottom: 1px solid red;
      }
      .truck-number-center{
        text-align:center;
      }
    }
    ::v-deep {
      .title{
        color: #000;
        font-size: 16px;
      }
      .el-table__expanded-cell[class*=cell] {
        padding-top: 0 !important;
      }
      .expanded {
        background-color: #ecf5ff;
      }
      .el-table td.el-table__cell{
        border-top: 1px solid #EBEEF5;
        border-bottom: none;
      }
    }
  }
}
</style>
